import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import extranjeria1_1280x854 from "../assets/img/proyecto/abogado de extranjeria/extranjeria2.jpg";
import extranjeria2_1280x854 from "../assets/img/proyecto/abogado de extranjeria/extranjeria4.jpeg";

import nieI from "../assets/img/proyecto/abogado de extranjeria/nie.png";
import nonlucrativaI from "../assets/img/proyecto/abogado de extranjeria/non lucrativa.png";
import tiporesidenciaI from "../assets/img/proyecto/abogado de extranjeria/tipo de residencia.png";
import visadoI from "../assets/img/proyecto/abogado de extranjeria/visado.png";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import 'bootstrap';

export default function Extranjeria() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="container">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto9")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={extranjeria1_1280x854}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={extranjeria2_1280x854}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          <br /><br /><br />

        <main>
          {/* Wrap the rest of the page in another container to center all the content. */}
          <div className="container marketing">
            {/* Three columns of text below the carousel */}
            <div className="row">
              <div className="col-lg-3 text-center">
              <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={nieI} />
              <h2 className="fw-normal">{t("extranjeria.texto1")}</h2>
                <NavLink to="/nie">
                <p><a className="btn colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                    </NavLink>
                    <br />
                    </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={nonlucrativaI} />
                <h2 className="fw-normal">{t("extranjeria.texto3")}</h2>
                <NavLink to="/nonlucrativa">
                <p><a className="btn  colordiv colortext">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={tiporesidenciaI} />
                <h2 className="fw-normal">{t("extranjeria.texto4")}</h2>
                <NavLink to="/tiporesidencia">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
              <div className="col-lg-3 text-center">
                <img className="bd-placeholder-img rounded-circle" width={140} height={140} src={visadoI} />
                <h2 className="fw-normal">{t("extranjeria.texto5")}</h2>
                <NavLink to="/visado">
                <p><a className="btn  colordiv colortext" href="#">{t("textos.texto1")} »</a></p>
                </NavLink>
                <br />
                </div>{/* /.col-lg-4 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </main>
      </div>

        <br />
        <nav className="text-center blog-pagination" aria-label="Pagination">
          <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
        </nav><br />

      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .color-fuente {
    background-color: #222;
  }
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }


      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        width: 100%;
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      .btn-bd-primary {
        --bd-violet-bg: #712cf9;
        --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet-bg);
        --bs-btn-border-color: var(--bd-violet-bg);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #6528e0;
        --bs-btn-hover-border-color: #6528e0;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #5a23c8;
        --bs-btn-active-border-color: #5a23c8;
      }

      .bd-mode-toggle {
        z-index: 1500;
      }

      .bd-mode-toggle .dropdown-menu .active .bi {
        display: block !important;
      }


  `;
