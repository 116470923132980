import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';

export default function Register() {
//  const baseUrl = "http://localhost/api-legalallies/usuario.php";
  const baseUrl = "https://api.legalallies.es/usuario.php";
  
  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [permiso, setPermiso] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    id: '',
    usuario: '',
    nombres: '',
    apellidos: '',
    contrasena: '',
    contrasenar: '',
    fecha: '',
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(false);
  const [existe, setExiste] = useState(false);

  const cifrar = (texto, clave) => {
    var textoCifrado = CryptoJS.AES.encrypt(texto, clave).toString();
    return textoCifrado;
  }

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    peticionGet();
  }, []);

  const usuarioValido = ()=>{
    var existencia = false;
    data.map((x)=>{
      if(x.usuario_usuario == usuarioSeleccionado.usuario){
        existencia = true;
      }
    });
    if(existencia){
      setExiste(true);
      setErrorEnviar("El usuario ya existe.")
    }else{
      setExiste(false);
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    if(usuarioSeleccionado.usuario && usuarioSeleccionado.nombres && usuarioSeleccionado.apellidos && usuarioSeleccionado.contrasena && usuarioSeleccionado.contrasenar){
      usuarioValido();
      setPermiso(true);
      setErrorEnviar(null);
    }else{
      setErrorEnviar("Complete el formulario...")
    }
  };

  const peticionPost = async () => {
  if(permiso){
    if(!existe){
      if(usuarioSeleccionado.contrasena == usuarioSeleccionado.contrasenar){
      var f = new FormData();
      f.append("usuario", usuarioSeleccionado.usuario);
      f.append("nombres", usuarioSeleccionado.nombres);
      f.append("apellidos", usuarioSeleccionado.apellidos);
      f.append("contrasena", cifrar(usuarioSeleccionado.contrasena, usuarioSeleccionado.usuario));
      f.append("rol", 1);
      f.append("METHOD", "POST");
      await axios.post(baseUrl, f).then(response => {
        setData(data.concat(response.data));
        setEspera(true);
        setErrorEnviar("Se ha creado correctamente su usuario. Ya puede INICIAR SESIÓN...")
      }).catch(error => {
        console.log(error);
        setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
      })

    }else{
      setErrorEnviar("Escribió mal la contraseña")
    }
  }else{
    setErrorEnviar("El correo ya está asignado a un usuario.")
  }
}else{
      setErrorEnviar("Complete el formulario.")
    }
  };

  return (
    <>
      <body className="hold-transition login-page login">
        <div className="register-box">
          <div className="card">
            <div className="card-body register-card-body">
              <p className="login-box-msg">Registrarse</p>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Nombre"  name='nombres' onChange={handleChange} required/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Apellidos"  name='apellidos' onChange={handleChange} required/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="email" className="form-control" placeholder="Correo Electrónico" name='usuario' onChange={handleChange} required={true}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Contraseña" name='contrasena' onChange={handleChange} required/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Repetir Contraseña" name='contrasenar' onChange={handleChange} required/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                  </div>
                </div>
                {
                  errorEnviar && !espera &&
                  <div className="alert alert-danger">
                    {errorEnviar}
                  </div>
                }
                { espera && 
                <div className="alert alert-success">
                  {errorEnviar}
                </div>
                }
                <button disabled={espera} type="submit" className="btn btn-primary btn-block"  onClick={() => peticionPost()}>Aceptar</button>
                <br /><br />
              <NavLink to="/login">
                <p className="mb-0">
                  <a href="register.html" className="text-center">Ya estoy registrado</a>
                </p>
              </NavLink>
              <NavLink to="/">
                <p className="mb-0">
                  <a className="text-center">Regresar a Inicio</a>
                </p>
              </NavLink>
            </div>
            {/* /.form-box */}
          </div>{/* /.card */}
        </div>
      </body>
    </>
  );
}

const NavContainer = styled.nav`
button {
    border: none;
}

.boton-transparente {
  display: inline-block;
  width: 200px;
  padding: 8px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
  transition: background-color 0.2s ease-out,
              color 0.2s ease-out;
}
.color-blanco{
  color: #FFFFFF;
}
.color-azul{
  color: #001952;
}
.boton-transparente:hover,
.boton-transparente:active {
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s ease-in,
              color 0.3s ease-in;
}
                  
h2{
    color:  #d4ac0d ;
    font-weight: 400;
    span{
        font-weight:bold;
        color: white;
    }
    padding: .6rem;
}

h3{
  color:  #d4ac0d ;
  font-weight: 400;
  span{
      font-weight:bold;
      color: white;
  }
  padding: .6rem;
}

img{
    font-weight: 400;
    span{
        font-weight:bold;
    }
    padding: .6rem;
}
padding: .4rem;
background: linear-gradient(to bottom right, #001952 60%, pink);
align-items: center;
justify-content: space-between;
a{
    color: while;
    text-decoration: none;
    margin-right: 2rem;
}
.links{
    position:absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    a{
        color: white;
        font-size: 2rem;
        display: block;
    }
    @media(min-width: 768px){
        position: initial;
        margin: 0;
        a{
            font-size: 1rem;
            color: white;
            display: inline;
        }
    }
}
.links.active{
    width: 100%
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    a{
        font-size: rem;
        margin-top: 1rem;
        color: white;
    }
}
.burguer{
    a{
        color: white;
        font-size: 1rem;
        display: block;
        margin: 10px 10px;
        position: relative;
        cursor: pointer;
        display: inline-block;
    }
    @media(min-width: 768px){
        display: none;
    }
}
`;

const BgDiv = styled.div`
  background-color: #222;
  position: absolute;
  top: -700px;
  left: -1000px;
  width: 100%;
  height: 100%;
  z-index: -1;
  &.active {
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;