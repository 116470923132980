import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { NavLink } from "react-router-dom";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";

function Texto() {
  //  const baseUrl = "http://localhost/api-legalallies/texto.php";
  //  const baseUrl = "https://api.legalallies.es/";
  const baseUrl = "https://api.legalallies.es/texto.php";

  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [textoSeleccionado, setTextoSeleccionado] = useState({
    id: '',
    documento: '',
    denominacion: '',
    codigo: '',
    texto: '',
    idioma: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setTextoSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    if (textoSeleccionado.documento && textoSeleccionado.denominacion && textoSeleccionado.codigo && textoSeleccionado.texto && textoSeleccionado.idioma) {
      setEspera(false);
      setErrorEnviar(false);
    }
    console.log(textoSeleccionado.texto);
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    var f = new FormData();
    f.append("documento", textoSeleccionado.documento);
    f.append("denominacion", textoSeleccionado.denominacion);
    f.append("codigo", textoSeleccionado.codigo);
    f.append("texto", textoSeleccionado.texto);
    f.append("idioma", textoSeleccionado.idioma);
    f.append("METHOD", "POST");
    await axios.post(baseUrl, f).then(response => {
      setData(data.concat(response.data));
      abrirCerrarModalInsertar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("documento", textoSeleccionado.documento);
    f.append("denominacion", textoSeleccionado.denominacion);
    f.append("codigo", textoSeleccionado.codigo);
    f.append("texto", textoSeleccionado.texto);
    f.append("idioma", textoSeleccionado.idioma);
    f.append("METHOD", "PUT");
    await axios.post(baseUrl, f, { params: { id: textoSeleccionado.id } }).then(response => {
      var dataNueva = data;
      dataNueva.map(texto => {
        if (texto.id === textoSeleccionado.id) {
          texto.documento = textoSeleccionado.documento;
          texto.denominacion = textoSeleccionado.denominacion;
          texto.codigo = textoSeleccionado.codigo;
          texto.texto = textoSeleccionado.texto;
          texto.idioma = textoSeleccionado.idioma;
        }
      });
      setData(dataNueva);
      abrirCerrarModalEditar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, { params: { id: textoSeleccionado.id } }).then(response => {
      setData(data.filter(texto => texto.id !== textoSeleccionado.id));
      abrirCerrarModalEliminar();
    }).catch(error => {
      console.log(error);
    })
  };

  const seleccionarTexto = (texto, caso) => {
    setTextoSeleccionado(texto);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div>

      <br />

      <div  style={{ textAlign: 'center' }}>
        <button className='btn btn-success' onClick={() => abrirCerrarModalInsertar()}>Insertar</button>
      </div>

      <div  style={{ textAlign: 'right' }}>
        <NavLink to="/configuracion">
          <a data-widget="pushmenu" href="#" role="button"><i class="fas fa-cog"></i></a>
        </NavLink>
      </div>

      <table className='table table-bordered table-hover table-striped table-responsive'>
        <thead>
          <tr>
            <th>Documento</th>
            <th>Denominación</th>
            <th>Código</th>
            <th>Texto</th>
            <th>Idioma</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map(texto => (
            <tr key={texto.id}>
              <td>{texto.documento}</td>
              <td>{texto.denominacion}</td>
              <td>{texto.codigo}</td>
              <td>{texto.texto}</td>
              <td>{texto.idioma}</td>
              <td>
                <a className="nav-link" onClick={() => seleccionarTexto(texto, "Editar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-edit"></i></a>
                <a className="nav-link" onClick={() => seleccionarTexto(texto, "Eliminar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Texto</ModalHeader>
        <ModalBody>
          <div className='form-group'>

            <label>Documento: </label><br />
            <input type="text" className='form-control' name='documento' onChange={handleChange} required /><br />
            <label>Denominación: </label><br />
            <input type="text" className='form-control' name='denominacion' onChange={handleChange} required /><br />
            <label>Código: </label><br />
            <input type="text" className='form-control' name='codigo' onChange={handleChange} required /><br />
            <label>Texto: </label><br />
            <input type="text" className='form-control' name='texto' onChange={handleChange} required /><br />
            <label>Idioma: </label><br />
            <input type="text" className='form-control' name='idioma' onChange={handleChange} required /><br />
          </div>
          {
            errorEnviar &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>

          }
        </ModalBody>
        <ModalFooter>
          <button disabled={false} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditar}>
        <ModalHeader>Editar Texto</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label>Documento: </label><br />
            <input type="text" className='form-control' name='documento' onChange={handleChange} value={textoSeleccionado && textoSeleccionado.documento} /><br />
            <label>Denominación: </label><br />
            <input type="text" className='form-control' name='denominacion' onChange={handleChange} value={textoSeleccionado && textoSeleccionado.denominacion} /><br />
            <label>Código: </label><br />
            <input type="text" className='form-control' name='codigo' onChange={handleChange} value={textoSeleccionado && textoSeleccionado.codigo} /><br />
            <label>Texto: </label><br />
            <input type="text" className='form-control' name='texto' onChange={handleChange} value={textoSeleccionado && textoSeleccionado.texto} /><br />
            <label>Idioma: </label><br />
            <input type="text" className='form-control' name='idioma' onChange={handleChange} value={textoSeleccionado && textoSeleccionado.idioma} /><br />
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionPut()}>Editar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar}>
        <ModalHeader>Eliminar Texto</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas eliminar el Texto {textoSeleccionado.texto}?
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionDelete()}>Sí</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEliminar()}>No</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Texto