import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faLock, faPencil, faPlus, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';

function Usuariogestion({ acceso }) {
//  const baseUrl = "http://localhost/api-legalallies/usuario.php";
  const baseUrl = "https://api.legalallies.es/usuario.php";

  const [permiso, setPermiso] = useState(false);
  var contador = 0;
  const [existe, setExiste] = useState(false);
  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    usuario_usuario: '',
    usuario_nombres: '',
    usuario_apellidos: '',
    usuario_contrasena: '',
    contrasenar: '',
    usuario_fecha: '',
    usuario_rol: '',
    rol_nombre: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [mensaje, setMensaje] = useState(null);
  const [espera, setEspera] = useState(false);

  const cifrar = (texto, clave) => {
    var textoCifrado = CryptoJS.AES.encrypt(texto, clave).toString();
    return textoCifrado;
  }
  const descifrar = (texto, clave) => {
    var bytes = CryptoJS.AES.decrypt(texto, clave);
    var textoDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    return textoDescifrado;
  }

  const usuarioValido = () => {
    var existencia = false;
    data.map((x) => {
      if (x.usuario_usuario == usuarioSeleccionado.usuario_usuario) {
        existencia = true;
      }
    });
    if (existencia) {
      setExiste(true);
      setErrorEnviar("El usuario ya existe.")
    } else {
      setExiste(false);
    }
  }


  const handleChange = e => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    if (usuarioSeleccionado.usuario_usuario && usuarioSeleccionado.usuario_nombres && usuarioSeleccionado.usuario_apellidos && usuarioSeleccionado.usuario_contrasena && usuarioSeleccionado.contrasenar) {
      usuarioValido();
      setPermiso(true);
      setErrorEnviar(null);
    } else {
      setErrorEnviar("Complete el formulario...")
    }
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
    if(espera){
      setEspera(false);
      setErrorEnviar(false);
    } 
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
    if(espera){
      setEspera(false);
      setErrorEnviar(false);
    } 
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    if (permiso) {
      if (!existe) {
        if (usuarioSeleccionado.usuario_contrasena == usuarioSeleccionado.contrasenar) {
          var f = new FormData();
          f.append("usuario_usuario", usuarioSeleccionado.usuario_usuario);
          f.append("usuario_nombres", usuarioSeleccionado.usuario_nombres);
          f.append("usuario_apellidos", usuarioSeleccionado.usuario_apellidos);
          f.append("usuario_contrasena", cifrar(usuarioSeleccionado.usuario_contrasena, usuarioSeleccionado.usuario_usuario));
          f.append("usuario_rol", '1');
          f.append("METHOD", "POST");
          await axios.post(baseUrl, f).then(response => {
            peticionGet();
            setEspera(true);
            setErrorEnviar("Se ha creado correctamente el usuario...")
          }).catch(error => {
            console.log(error);
            setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
          })

        } else {
          setErrorEnviar("Escribió mal la contraseña")
        }
      } else {
        setErrorEnviar("El correo ya está asignado a un usuario.")
      }
    } else {
      setErrorEnviar("Complete el formulario.")
    }
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("usuario_usuario", usuarioSeleccionado.usuario_usuario);
    f.append("usuario_nombres", usuarioSeleccionado.usuario_nombres);
    f.append("usuario_apellidos", usuarioSeleccionado.usuario_apellidos);
    f.append("usuario_contrasena", cifrar(usuarioSeleccionado.usuario_contrasena, usuarioSeleccionado.usuario_usuario));
    f.append("usuario_rol", usuarioSeleccionado.usuario_rol);
    f.append("METHOD", "PUT");
    await axios.post(baseUrl, f, { params: { usuario_usuario: usuarioSeleccionado.usuario_usuario } }).then(response => {
      var dataNueva = data;
      dataNueva.map(usuario => {
        if (usuario.usuario_usuario === usuarioSeleccionado.usuario_usuario) {
          usuario.usuario_usuario = usuarioSeleccionado.usuario_usuario;
          usuario.usuario_nombres = usuarioSeleccionado.usuario_nombres;
          usuario.usuario_apellidos = usuarioSeleccionado.usuario_apellidos;
          usuario.usuario_contrasena = usuarioSeleccionado.usuario_contrasena;
          usuario.usuario_rol = usuarioSeleccionado.usuario_rol;
        }
      });
      setData(dataNueva);
      abrirCerrarModalEditar();
    }).catch(error => {
      console.log(error);
    })
    peticionGet();
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, { params: { usuario_usuario: usuarioSeleccionado.usuario_usuario } }).then(response => {
//      setData(data.filter(usuario => usuario.usuario_usuario !== usuarioSeleccionado.usuario_usuario));
      abrirCerrarModalEliminar();
    }).catch(error => {
      console.log(error);
    })
    peticionGet();
  };

  const seleccionarUsuario = (usuario, caso) => {
    setUsuarioSeleccionado(usuario);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div>
      <div class="card  table-hover table-striped table-responsive">
            <div className="card-header">
              <br />
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/configuracion">Configuración</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          Gestionar Usuarios
                        </li>
                      </ol>

              <div style={{ textAlign: 'right' }}>
                <button className='btn btn-primary' onClick={() => abrirCerrarModalInsertar()}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
            </div>
            <div className="card-body">

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>#</th>
            <th>Usuario</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Rol</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map(usuario => (
            <tr key={usuario.usuario_usuario}>
              <td>{contador=Number(contador)+1}</td>
              <td>{usuario.usuario_usuario}</td>
              <td>{usuario.usuario_nombres}</td>
              <td>{usuario.usuario_apellidos}</td>
              <td>{usuario.rol_nombre}</td>
              <td>{usuario.usuario_fecha}</td>
              <td>
                <a className="btn btn-primary nav-link" onClick={() => seleccionarUsuario(usuario, "Editar")} data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faPencil} /></a>
                <a className="btn btn-primary nav-link" onClick={() => seleccionarUsuario(usuario, "Eliminar")} data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faTrashAlt} /></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

</div></div>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Usuario</ModalHeader>
        <ModalBody>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Nombre" name='usuario_nombres' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
              <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Apellidos" name='usuario_apellidos' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="Correo Electrónico" name='usuario_usuario' onChange={handleChange} required={true} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Contraseña" name='usuario_contrasena' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faLock} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Repetir Contraseña" name='contrasenar' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faLock} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button disabled={espera} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditar}>
        <ModalHeader>Editar Usuario</ModalHeader>
        <ModalBody>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Nombre" name='usuario_nombres' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.usuario_nombres} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Apellidos" name='usuario_apellidos' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.usuario_apellidos} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">@</span>
            </div>
            <input disabled type="email" className="form-control" placeholder="Correo Electrónico" name='usuario_usuario' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.usuario_usuario} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Contraseña" name='usuario_contrasena' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.usuario_contrasena} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faLock} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Repetir Contraseña" name='contrasenar' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.contrasenar} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faLock} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="number" className="form-control" placeholder="Rol" name='usuario_rol' onChange={handleChange} value={usuarioSeleccionado && usuarioSeleccionado.usuario_rol} />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button disabled={espera} className='btn btn-primary' onClick={() => peticionPut()}>Editar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar}>
        <ModalHeader>Eliminar Usuario</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas eliminar el Usuario {usuarioSeleccionado.usuario_usuario + ": " + usuarioSeleccionado.usuario_nombres + " " + usuarioSeleccionado.usuario_apellidos}?
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionDelete()}>Sí</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEliminar()}>No</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Usuariogestion