import React from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";

import familia1_1800x800 from "../assets/img/proyecto/derecho a la familia/familia1_1800x800.jpg";
import familia2_1800x800 from "../assets/img/proyecto/derecho a la familia/familia2_1800x800.jpg";
import familia3_1800x800 from "../assets/img/proyecto/derecho a la familia/familia3_1800x800.jpg";
import familia4_1800x800 from "../assets/img/proyecto/derecho a la familia/familia4_1800x800.jpg";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function Familia() {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <DivContainer>
        <div className="main-header">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <NavLink to="/">{t("navbar.page1")}</NavLink>
                    </li>
                    <li className="breadcrumb-item active">
                      {t("body.texto5")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators"></ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={familia1_1800x800}
                  alt="First slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia2_1800x800}
                  alt="Third slide"
                />
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia3_1800x800}
                  alt="Third slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100"
                  src={familia4_1800x800}
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
          <br />

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div>
                <div className="row">
                  <div className="col-md-3 col-sm-6 col-12">
                    <NavLink to="/custodia">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                          <NavLink to="/custodia">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                          </NavLink>
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("familia.texto1")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <NavLink to="/parejahecho">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("familia.texto3")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <NavLink to="/adopcion">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("familia.texto4")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <NavLink to="/divorcioespana">
                      <div className="info-box colordiv">
                        <span className="info-box-icon">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                        </span>
                        <div className="info-box-content">
                          <span className="info-box-text colortext">
                            {t("familia.texto5")}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{ width: "100%" }}
                            />
                          </div>
                          <span className="progress-description colortext"></span>
                        </div>
                        {/* /.info-box-content */}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<br />
    <nav className="text-center blog-pagination" aria-label="Pagination">
        <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
      </nav><br />

      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0.5rem;
  .colordiv {
    background-color: #001952;
  }
  .colortext {
    color: white;
  }
`;

const DivBoton = styled.div`
  padding: 0.4rem;
  background: linear-gradient(to bottom right, #001952 60%, pink);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
