import React from "react";
import { useAuth } from "../auth/AuthProvider";
import Home from "./Home";
import Forgotpasswordgestion from "./Forgotpasswordgestion";

export default function Forgotpassword() {
  const auth = useAuth();
  return (
      auth.isAuthenticated ? <Home /> : <Forgotpasswordgestion />
  );
}