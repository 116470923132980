import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { NavLink } from "react-router-dom";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";

function Documento() {
//  const baseUrl = "http://localhost/api-legalallies/documento.php";
//  const baseUrl = "https://api.legalallies.es/";
  const baseUrl = "https://api.legalallies.es/documento.php";
  
  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState({
    id: '',
    nombre: '',
    fecha: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setDocumentoSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    if(documentoSeleccionado.documento && documentoSeleccionado.nombre){
      setEspera(false);
      setErrorEnviar(false);
    }
    console.log(documentoSeleccionado.documento);
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    var f = new FormData();
    f.append("nombre", documentoSeleccionado.nombre);
    f.append("METHOD", "POST");
    await axios.post(baseUrl, f).then(response => {
      setData(data.concat(response.data));
      abrirCerrarModalInsertar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("nombre", documentoSeleccionado.nombre);
    f.append("METHOD", "PUT");
    await axios.post(baseUrl, f, { params: { id: documentoSeleccionado.id } }).then(response => {
      var dataNueva = data;
      dataNueva.map(documento => {
        if (documento.id === documentoSeleccionado.id) {
          documento.nombre = documentoSeleccionado.nombre;
        }
      });
      setData(dataNueva);
      abrirCerrarModalEditar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, { params: { id: documentoSeleccionado.id } }).then(response => {
      setData(data.filter(documento => documento.id !== documentoSeleccionado.id));
      abrirCerrarModalEliminar();
    }).catch(error => {
      console.log(error);
    })
  };

  const seleccionarDocumento = (documento, caso) => {
    setDocumentoSeleccionado(documento);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div style={{ textAlign: 'center' }}>

      <br />
      <div  style={{ textAlign: 'center' }}>
        <button className='btn btn-success' onClick={() => abrirCerrarModalInsertar()}>Insertar</button>
      </div>

      <div  style={{ textAlign: 'right' }}>
        <NavLink to="/configuracion">
          <a data-widget="pushmenu" href="#" role="button"><i class="fas fa-cog"></i></a>
        </NavLink>
      </div>
      <table className='table table-bordered table-hover table-striped table-responsive'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Fecha</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map(documento => (
            <tr key={documento.id}>
              <td>{documento.nombre}</td>
              <td>{documento.fecha}</td>
              <td>
                <a className="nav-link"  onClick={() => seleccionarDocumento(documento, "Editar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-edit"></i></a>
                <a className="nav-link"  onClick={() => seleccionarDocumento(documento, "Eliminar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Documento</ModalHeader>
        <ModalBody>
          <div className='form-group'>

            <label>Nombre: </label><br />
            <input type="text" className='form-control' name='nombre' onChange={handleChange} required/><br />
          </div>
          {
            errorEnviar && 
            <div className="alert alert-danger">
              {errorEnviar}
            </div>

          }
        </ModalBody>
        <ModalFooter>
          <button disabled={false} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditar}>
        <ModalHeader>Editar Documento</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label>Nombre: </label><br />
            <input type="text" className='form-control' name='nombre' onChange={handleChange} value={documentoSeleccionado && documentoSeleccionado.nombre} /><br />
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionPut()}>Editar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar}>
        <ModalHeader>Eliminar Documento</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas eliminar el Documento {documentoSeleccionado.nombre}?
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionDelete()}>Sí</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEliminar()}>No</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Documento