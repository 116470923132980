import React from "react";
import { NavLink } from "react-router-dom";
import Logingestion from "./Logingestion";
import Home from "./Home";
import { useAuth } from "../auth/AuthProvider";

export default function Login() {
  const auth = useAuth();
  return (
      auth.isAuthenticated ? <Home /> : <Logingestion />
  );
}