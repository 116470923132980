import React, { useState, useEffect } from 'react';
import Articulogestion from './Articulogestion';
import { useAuth } from '../auth/AuthProvider';
import Login from './Login';

function Articulo() {
  const auth = useAuth();

  return (
//    auth.isAuthenticated ? <Articulogestion /> : <Articulogestion />
    auth.isAuthenticated && auth.rol == 3 ? <Articulogestion /> : <Login />
)
}

export default Articulo