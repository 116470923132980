import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import background from "../assets/img/proyecto/derecho a la familia/custodia/1800x800/2.jpg";
import "../assets/css/all.min.css";

import 'bootstrap/dist/css/bootstrap.min.css';
//import '../assets/css/mycss.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { NavLink } from "react-router-dom";
import imageng from '../assets/img/paises/icons8-albania-emoji-48.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFileImage, faFileText, faIdCard, faKey, faLock, faMailForward, faPencil, faPlus, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { exists } from 'i18next';
import { useAuth } from '../auth/AuthProvider';

function ArticulogestionDocumento({ documento, urlweb }) {
  const auth = useAuth();

  const [t, i18n] = useTranslation("global");
  const [idioma, setIdioma] = i18n.language;

  //const baseUrl = "http://localhost/api-legalallies/articulo.php";
  const baseUrl = "https://api.legalallies.es/articulo.php";

  const [permiso, setPermiso] = useState(false);
  var contador = 0;
  const [data, setData] = useState([]);
  const [urlimagenes, setUrlimagenes] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEliminarMensaje, setModalEliminarMensaje] = useState(false);
  const [mensaje, setMensaje] = useState(false);
  const [articulo_imagen, setArticuloImagen] = useState(null);
  const [articulo_imagen_old, setArticuloImagenOld] = useState(null);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({
    articulo_id: '',
    articulo_nombre: '',
    articulo_fechacreacion: '',
    articulo_url: '',
    articulo_descripcion: '',
    articulo_texto: '',
    articulo_documento: '',
    articulo_imagen: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setArticuloSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto ) {
      setPermiso(true);
      setErrorEnviar(null);
    }
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloImagen(null);
    setFile(null);
  };

  const abrirCerrarModalEliminarMensaje = () => {
    setModalEliminarMensaje(!modalEliminarMensaje);

    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl, {"articulo_documento": documento, "idioma": idioma}).then(response => {
      setData(response.data["resultado"]);
      setUrlimagenes(response.data["urlimagenes"]);
      console.log(response)
//      console.log(response.data["urlimagenes"] + response.data["resultado"][0]["articulo_imagen"]);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto && articulo_imagen) {
      setPermiso(true);
      setErrorEnviar(null);
    } else {
      setErrorEnviar("Complete el formulario...");
      return;
    }

    if (permiso) {

      var f = new FormData();
      f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
      f.append("articulo_url", urlweb);
      f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
      f.append("articulo_texto", articuloSeleccionado.articulo_texto);
      f.append("articulo_documento", documento);
      f.append("articulo_documento_id", 1);
      f.append("articulo_imagen", articulo_imagen);
      f.append("METHOD", "POST");

      await axios.post(baseUrl, f, {
        headers: { 'Content-Type': "multipart/form-data" },
      }).then(response => {
        peticionGet();
        setEspera(true);
        setErrorEnviar("Se ha creado correctamente el articulo...")
        console.log(response);
      }).catch(error => {
        console.log(error);
        setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
      })

    } else {
      setErrorEnviar("Complete el formulario.")
    }
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("articulo_id", articuloSeleccionado.articulo_id);
    f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
    f.append("articulo_url", articuloSeleccionado.articulo_url);
    f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
    f.append("articulo_texto", articuloSeleccionado.articulo_texto);
    f.append("articulo_documento", articuloSeleccionado.articulo_documento);
    f.append("articulo_documento_id", 1);
    f.append("articulo_imagen_old", articulo_imagen_old);
    f.append("articulo_imagen", articulo_imagen);
    f.append("METHOD", "PUT");

    await axios.post(baseUrl, f, { params: { articulo_id: articuloSeleccionado.articulo_id } }).then(response => {
      var dataNueva = data;
      dataNueva.map(articulo => {
        if (articulo.articulo_id === articuloSeleccionado.articulo_id) {
          articulo.articulo_nombre = articuloSeleccionado.articulo_nombre;
          articulo.articulo_url = articuloSeleccionado.articulo_url;
          articulo.articulo_descripcion = articuloSeleccionado.articulo_descripcion;
          articulo.articulo_texto = articuloSeleccionado.articulo_texto;
          articulo.articulo_documento = articuloSeleccionado.articulo_documento;
          articulo.articulo_imagen = articulo_imagen;
        }
      });

      setData(dataNueva);
      abrirCerrarModalEditar();
      //      
      console.log(response);
    }).catch(error => {
      console.log(error);
    })
    peticionGet();
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("articulo_id", articuloSeleccionado.articulo_id);
    f.append("articulo_imagen_old", articulo_imagen_old);
    f.append("METHOD", "DELETE");
    const confirmacion = await axios.post(baseUrl, f, { params: { articulo_id: articuloSeleccionado.articulo_id } }).then(response => {
      //      setData(data.filter(articulo => articulo.articulo_articulo !== articuloSeleccionado.articulo_articulo));
      abrirCerrarModalEliminar();
      abrirCerrarModalEliminarMensaje();
    }).catch(error => {
      console.log(error);
    })
    peticionGet();

  };

  const seleccionararticulo = (articulo, caso) => {
    setArticuloSeleccionado(articulo);
    setArticuloImagenOld(articulo.articulo_imagen);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  const [file, setFile] = useState();

  function convertirImagen(imagen) {
    return URL.createObjectURL(imagen);
  }

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (selectedFile && selectedFile.type !== 'image/png' && selectedFile.type !== 'image/jpeg') {
        alert('Seleccione una imagen PNG o JPEG.');
        return;
      }
      if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {  // 1 MB
        alert('El tamaño del archivo supera 1 MB');
        return;
      }

      setArticuloImagen(e.target.files[0]);

      setFile(URL.createObjectURL(e.target.files[0]));

    } else {
      return;
    }
  }


  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div>


      <DivContainer>
        <div className="main-header">
          <br />
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">

            <section className="content-header">
                      <div className="card-header">
                        <br />
      
                        <h3 className='text-center color-blanco'>Artículos Relacionados</h3>
      
                        {
                      auth.isAuthenticated && auth.rol == 3
                      ? 
                      <div style={{ textAlign: 'right' }}>
                        <button className='btn btn-primary' onClick={() => abrirCerrarModalInsertar()}><FontAwesomeIcon icon={faPlus} /></button>
                      </div>
                      : 
                      true
              }              
                      </div>
                    </section>
              {data.map(articulo => (

                articulo.articulo_documento == documento
                ?
                <div className="card card-widget">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators"></ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          className="d-block w-100 h-50"
                          src={"https://api.legalallies.es/imagenes/" + articulo.articulo_imagen}
//                          src={"http://localhost/api-legalallies/imagenes/" + articulo.articulo_imagen}
                          alt="First slide"
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="row">
                    <div className="col-md-12">
                      {/* Box Comment */}
                      {/* /.card-header */}
                      <div className="card-body">
                        <h6 className='text-right'>{articulo.articulo_fechacreacion}</h6>
                        <h4>{articulo.articulo_nombre}</h4>
                        <br />
                        <p>{articulo.articulo_descripcion}</p>
                        <h6>{articulo.articulo_texto}</h6>
                        <br />
                        <h6 className='text-right'>URL: {articulo.articulo_url}</h6>
                      </div>
                      {/* /.card-footer */}
                      {/* /.card */}
                    </div>
                    {/* /.col */}
                  </div>
                </div>
                :
                true
              ))}
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
        </div>
      </DivContainer>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Artículo</ModalHeader>
        <ModalBody>
          <div className="input-group mb-3">
            <input type="file" className="form-control" placeholder="Imagen" name='articulo_imagen' onChange={handleChangeImage} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            </div><br />
          </div>
          <img width={"250px"} src={file} />
          <br /><br />
          <div className="input-group mb-3">
            <input disabled={true} type="text" className="form-control" placeholder="URL" name='articulo_url' onChange={handleChange} value={urlweb} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faMailForward} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input disabled={true} type="text" className="form-control" placeholder="Documento" name='articulo_documento' value={documento} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Título" name='articulo_nombre' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faIdCard} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Descripción" name='articulo_descripcion' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Texto" name='articulo_texto' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button disabled={espera} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ArticulogestionDocumento

const DivContainer = styled.div`
  padding: 0rem;
  background-color: #343a40;
  h3 {
    padding: 0.6rem;
  }

.color-blanco{
  color: #FFFFFF;
}  
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;
