import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import "../assets/css/mycss.css";
import styled from "styled-components";
import background from "../assets/img/proyecto/derecho laboral/acoso/1800x800/3.jpg";
import { useTranslation } from "react-i18next";

import { useAuth } from "../auth/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faEnvelope, faFileImage, faFileText, faIdCard, faKey, faLock, faMailForward, faPencil, faPlus, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap';
import lateral from "../assets/img/proyecto/derecho laboral/acoso/1800x800/1.jpg";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
export default function Acosolaboral() {
  const auth = useAuth();

  const [t, i18n] = useTranslation("global");

  const [articulos, setArticulos] = useState(false);
  const documento = "Acoso Laboral";
  const urlweb = "https://legalallies.es/acosolaboral";
  const abrircerrararticulos = () => {
    setArticulos(!articulos);
  };


  //const baseUrl = "http://localhost/api-legalallies/articulo.php";
  const baseUrl = "https://api.legalallies.es/articulo.php";

  const [idioma, setIdioma] = i18n.language;
  const [data, setData] = useState([]);
  const [urlimagenes, setUrlimagenes] = useState([]);
  const [permiso, setPermiso] = useState(false);
  const [articulo_imagen, setArticuloImagen] = useState(null);
  const [articulo_imagen_old, setArticuloImagenOld] = useState(null);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalArticulo, setModalArticulo] = useState(false);
  const [espera, setEspera] = useState(false);
  const [file, setFile] = useState();

  const [errorEnviar, setErrorEnviar] = useState(null);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({
    articulo_id: '',
    articulo_nombre: '',
    articulo_fechacreacion: '',
    articulo_url: '',
    articulo_descripcion: '',
    articulo_texto: '',
    articulo_documento: '',
    articulo_imagen: ''
  });
  const handleChange = e => {
    const { name, value } = e.target;
    setArticuloSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto) {
      setPermiso(true);
      setErrorEnviar(null);
    }
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
  };

  const abrirCerrarModalArticulo = () => {
    setModalArticulo(!modalArticulo);
  };

  const peticionGet = async () => {
    //    console.log(t("idioma.texto1"));
    await axios.get(baseUrl).then(response => {
      setData(response.data["resultado"]);
      setUrlimagenes(response.data["urlimagenes"]);
      //      console.log(response.data['resultado'])
      //      console.log(response.data["urlimagenes"] + response.data["resultado"][0]["articulo_imagen"]);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto && articulo_imagen) {
      setPermiso(true);
      setErrorEnviar(null);
    } else {
      setErrorEnviar("Complete el formulario...");
      return;
    }

    if (permiso) {

      var f = new FormData();
      f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
      f.append("articulo_url", urlweb);
      f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
      f.append("articulo_texto", articuloSeleccionado.articulo_texto);
      f.append("articulo_documento", documento);
      f.append("articulo_idioma", t("idioma.texto1"));
      f.append("articulo_documento_id", 1);
      f.append("articulo_imagen", articulo_imagen);
      f.append("METHOD", "POST");

      await axios.post(baseUrl, f, {
        headers: { 'Content-Type': "multipart/form-data" },
      }).then(response => {
        peticionGet();
        setEspera(true);
        setErrorEnviar("Se ha creado correctamente el articulo...")
        //        console.log(response);
      }).catch(error => {
        console.log(error);
        setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
      })

    } else {
      setErrorEnviar("Complete el formulario.")
    }
  };

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (selectedFile && selectedFile.type !== 'image/png' && selectedFile.type !== 'image/jpeg') {
        alert('Seleccione una imagen PNG o JPEG.');
        return;
      }
      if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {  // 1 MB
        alert('El tamaño del archivo supera 1 MB');
        return;
      }

      setArticuloImagen(e.target.files[0]);

      setFile(URL.createObjectURL(e.target.files[0]));

    } else {
      return;
    }
  }


  const seleccionararticulo = (articulo) => {
    setArticuloSeleccionado(articulo);
    abrirCerrarModalArticulo();
  };


  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <>
      <DivContainer>
        <div>
          <main className="container">
            <br />
            <h5 className="pb-4 mb-4 fst-italic border-bottom">
            <NavLink to="/laboral">{t("body.texto6")}</NavLink> / {t("laboral.texto1")}
            </h5>

            <div className="row g-5">
              <div className="col-md-8">

                <div className="p-4 mb-3 bg-body-tertiary rounded">
                  <img className="d-block w-100" src={background} alt="First slide" />
                </div>
                <article className="blog-post">
                  <h3 className="display-5 link-body-emphasis mb-1">{t("laboral.texto1")}</h3>
                  <p className="blog-post-meta">January 1, 2024</p>
                  <p>{t("acosolaboral.texto1")}</p>
                  <p>{t("acosolaboral.texto2")}</p>
                  <hr />
                  <h3>{t("acosolaboral.texto3")}</h3>
                  <p>{t("acosolaboral.texto4")}</p>
                  <ol>
                    <li>{t("acosolaboral.texto5")}</li>
                    <li>{t("acosolaboral.texto6")}</li>
                    <li>{t("acosolaboral.texto7")}</li>
                    <li>{t("acosolaboral.texto8")}</li>
                    <li>{t("acosolaboral.texto9")}</li>
                  </ol>
                  <p>{t("acosolaboral.texto10")}</p>
                  <dl>
                    <dt>{t("acosolaboral.texto11")}</dt>
                  </dl>
                </article>
              </div>
              <div className="col-md-4">
                <div className="position-sticky" style={{ top: '2rem' }}>
                  <div className="p-4 mb-3 bg-body-tertiary rounded">
                    <img className="d-block w-100" src={lateral} alt="First slide" />
                  </div>
                  <div>
                    <h4 className="fst-italic">Artículos Relacionados</h4>
                    {
                      auth.isAuthenticated && auth.rol == 3
                        ?
                        <div style={{ textAlign: 'right' }}>
                          <button className='btn btn-primary' onClick={() => abrirCerrarModalInsertar()}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                        :
                        true
                    }
                    {
                      !auth.isAuthenticated
                        ?
                        <div>
                        <h6 className="mb-0">"Debe autenticarse para ver los Artículos Relacionados"</h6><br />
                        <nav className="text-center blog-pagination" aria-label="Pagination">
                          <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
                        </nav><br />
                        </div>
                        :
                        true

                    }
                    <ul className="list-unstyled">
                      {data.map(articulo => (
                        auth.isAuthenticated && articulo.articulo_documento == documento && articulo.articulo_idioma == t("idioma.texto1")
                          ?
                          <li>
                            <a className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" onClick={() => seleccionararticulo(articulo)}>
                              <img className="d-block w-50 h-50" src={"https://api.legalallies.es/imagenes/" + articulo.articulo_imagen} alt="First slide" />
                              <div className="col-lg-8">
                                <h6 className="mb-0">{articulo.articulo_nombre}</h6>
                                <small className="text-body-secondary">{articulo.articulo_fechacreacion}</small>
                              </div>
                            </a>
                          </li>
                          :
                          true
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <div className="py-5 text-center text-body-secondary bg-body-tertiary">
          </div>
        </div>

        <Modal isOpen={modalInsertar}>
          <ModalHeader>Insertar Artículo</ModalHeader>
          <ModalBody>
            <div className="input-group mb-3">
              <input type="file" className="form-control" placeholder="Imagen" name='articulo_imagen' onChange={handleChangeImage} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faFileImage} />
                </div>
              </div><br />
            </div>
            <img width={"250px"} src={file} />
            <br /><br />
            <div className="input-group mb-3">
              <input disabled={true} type="text" className="form-control" placeholder="Documento" name='articulo_documento' value={documento} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faFileText} />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Título" name='articulo_nombre' onChange={handleChange} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faIdCard} />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Descripción" name='articulo_descripcion' onChange={handleChange} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faFileText} />
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Texto" name='articulo_texto' onChange={handleChange} required />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faFileText} />
                </div>
              </div>
            </div>

            {
              errorEnviar && !espera &&
              <div className="alert alert-danger">
                {errorEnviar}
              </div>
            }
            {espera &&
              <div className="alert alert-success">
                {errorEnviar}
              </div>
            }
          </ModalBody>
          <ModalFooter>
            <button disabled={espera} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
            <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalArticulo}>
          <ModalHeader><h3 className="text-center">{t("laboral.texto1")}</h3></ModalHeader>
          <ModalBody>


            <main className="container">

              <div className="row g-5">
                <div className="col-md-12">
                  <div className="p-4 mb-3 bg-body-tertiary rounded">
                    <img className="d-block w-100" src={"https://api.legalallies.es/imagenes/" + articuloSeleccionado.articulo_imagen} alt="First slide" />
                  </div>
                  <article className="blog-post">
                    <p className="blog-post-meta">{articuloSeleccionado.articulo_fechacreacion}</p>
                    <p>{articuloSeleccionado.articulo_descripcion}</p>
                    <hr />
                    <h3>{articuloSeleccionado.articulo_nombre}</h3>
                    <p>{articuloSeleccionado.articulo_texto}</p>
                  </article>
                </div>
              </div>
            </main>

          </ModalBody>
          <ModalFooter>
            <button className='btn btn-danger' onClick={() => abrirCerrarModalArticulo()}>Cerrar</button>
          </ModalFooter>
        </Modal>

        <nav className="text-center blog-pagination" aria-label="Pagination">
          <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
        </nav><br />
      </DivContainer>
    </>
  );
}

const DivContainer = styled.div`
  padding: 0rem;
  background-color:rgb(251, 251, 252);
  h3 {
    padding: 0.6rem;
  }
`;

const NavContainer = styled.nav`
  h2 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }

  padding: 0.9rem;
  background-color: #333;
  align-items: center;
  justify-content: space-between;
`;