import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { NavLink } from "react-router-dom";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faVcard } from '@fortawesome/free-solid-svg-icons';

function Configuraciongestion({ acceso }) {

  return (
    <div>

  <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/">Inicio</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          Gestionar Usuarios
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* /.container-fluid */}
              </section>
      <table className='table table-bordered table-hover table-striped'>
        <thead>
          <tr>
            <th>Nombres</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td><i class="fas fa-user"></i>Gestionar Usuarios</td>
              <td>
                <NavLink to="/usuario" >
                  <a className="nav-link" data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faUserFriends} /></a>
                </NavLink>
              </td>
            </tr>
            <tr>
              <td><i class="fas fa-user"></i>Gestionar Artículos</td>
              <td>
                <NavLink to="/articulo" >
                  <a className="nav-link" data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faVcard} /></a>
                </NavLink>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Configuraciongestion