import React, { useRef } from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const Mailer = () => {
  const [t, i18n] = useTranslation("global");

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const form = useRef();

  let valorUsd = "SUCCESS!";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_yq3zlm7", "template_mro6tsh", form.current, {
        publicKey: "Z-m_bPRouS1W3XdUw",
      })
      .then(
        () => {
          setOpen(true);
          form.current.reset();
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          setOpen(false);
        }
      );
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
        <label>
          <h6>{t("formulariocontacto.texto1")}</h6>
        </label>
        <input type="text" name="user_name" required />
        <br />
        <label>
          <h6>{t("formulariocontacto.texto2")}</h6>
        </label>
        <input type="email" name="user_email" required />
        <br />
        <label>
          <h6>{t("formulariocontacto.texto3")}</h6>
        </label>
        <textarea name="message" required />
        <br />
        <input
          className="btn_transparent btn btn-primary"
          type="submit"
          value={t("formulariocontacto.texto5")}
        />
      </form>

      <Modal open={open} onClose={onCloseModal}>
        <h6>{t("formulariocontacto.texto4")}</h6>
      </Modal>
    </div>
  );
};

export default Mailer;