import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { NavLink } from "react-router-dom";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";

function Idioma() {
//  const baseUrl = "http://localhost/api-legalallies/idioma.php";
  const baseUrl = "https://api.legalallies.es/idioma.php";
  const [data, setData] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState({
    id: '',
    nombre: '',
    sigla: '',
    foto: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(true);

  const handleChange = e => {
    const { name, value } = e.target;
    setIdiomaSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
    if(idiomaSeleccionado.idioma && idiomaSeleccionado.nombre && idiomaSeleccionado.sigla){
      setEspera(false);
      setErrorEnviar(false);
    }
    console.log(idiomaSeleccionado.idioma);
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
  };

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    var f = new FormData();
    f.append("nombre", idiomaSeleccionado.nombre);
    f.append("sigla", idiomaSeleccionado.sigla);
    f.append("foto", idiomaSeleccionado.foto);
    f.append("METHOD", "POST");
    await axios.post(baseUrl, f).then(response => {
      setData(data.concat(response.data));
      abrirCerrarModalInsertar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("nombre", idiomaSeleccionado.nombre);
    f.append("sigla", idiomaSeleccionado.sigla);
    f.append("foto", idiomaSeleccionado.foto);
    f.append("METHOD", "PUT");
    await axios.post(baseUrl, f, { params: { id: idiomaSeleccionado.id } }).then(response => {
      var dataNueva = data;
      dataNueva.map(idioma => {
        if (idioma.id === idiomaSeleccionado.id) {
          idioma.nombre = idiomaSeleccionado.nombre;
          idioma.sigla = idiomaSeleccionado.sigla;
          idioma.foto = idiomaSeleccionado.foto;
        }
      });
      setData(dataNueva);
      abrirCerrarModalEditar();
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, { params: { id: idiomaSeleccionado.id } }).then(response => {
      setData(data.filter(idioma => idioma.id !== idiomaSeleccionado.id));
      abrirCerrarModalEliminar();
    }).catch(error => {
      console.log(error);
    })
  };

  const seleccionarIdioma = (idioma, caso) => {
    setIdiomaSeleccionado(idioma);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div style={{ textAlign: 'center' }}>

      <br />
      <div  style={{ textAlign: 'center' }}>
        <button className='btn btn-success' onClick={() => abrirCerrarModalInsertar()}>Insertar</button>
      </div>

      <div  style={{ textAlign: 'right' }}>
        <NavLink to="/configuracion">
          <a data-widget="pushmenu" href="#" role="button"><i class="fas fa-cog"></i></a>
        </NavLink>
      </div>
      <table className='table table-bordered table-hover table-striped'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Sigla</th>
            <th>Foto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map(idioma => (
            <tr key={idioma.id}>
              <td>{idioma.nombre}</td>
              <td>{idioma.sigla}</td>
              <td>{idioma.foto}</td>
              <td>
                <a className="nav-link"  onClick={() => seleccionarIdioma(idioma, "Editar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-edit"></i></a>
                <a className="nav-link"  onClick={() => seleccionarIdioma(idioma, "Eliminar")} data-widget="pushmenu" href="#" role="button"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Idioma</ModalHeader>
        <ModalBody>
          <div className='form-group'>


            <label>Nombre: </label><br />
            <input type="text" className='form-control' name='nombre' onChange={handleChange} required/><br />
            <label>Sigla: </label><br />
            <input type="text" className='form-control' name='sigla' onChange={handleChange} required/><br />
            <label>Foto: </label><br />
            <input type="text" className='form-control' name='foto' onChange={handleChange} required/><br />
          </div>
          {
            errorEnviar && 
            <div className="alert alert-danger">
              {errorEnviar}
            </div>

          }
        </ModalBody>
        <ModalFooter>
          <button disabled={false} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditar}>
        <ModalHeader>Editar Idioma</ModalHeader>
        <ModalBody>
          <div className='form-group'>
            <label>Nombre: </label><br />
            <input type="text" className='form-control' name='nombre' onChange={handleChange} value={idiomaSeleccionado && idiomaSeleccionado.nombre} /><br />
            <label>Sigla: </label><br />
            <input type="text" className='form-control' name='sigla' onChange={handleChange} value={idiomaSeleccionado && idiomaSeleccionado.sigla} /><br />
            <label>Foto: </label><br />
            <input type="text" className='form-control' name='foto' onChange={handleChange} value={idiomaSeleccionado && idiomaSeleccionado.foto} /><br />
          </div>
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionPut()}>Editar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar}>
        <ModalHeader>Eliminar Idioma</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas eliminar el Idioma {idiomaSeleccionado.nombre}?
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionDelete()}>Sí</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEliminar()}>No</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Idioma