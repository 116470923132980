import React, { useEffect, useRef, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage, exists } from "i18next";
import "../assets/css/icheck-bootstrap/icheck-bootstrap.min.css";
import "../assets/css/login.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import  AuthProvider, { useAuth } from "../auth/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

export default function Logingestion() {
  const auth = useAuth();
  const goto = useNavigate();

  const [t, i18n] = useTranslation("global");

  const refUsuario = useRef(null);
  const refcontrasena = useRef(null);
  const [existe, setExiste] = useState(false);
  const [cvalida, setCvalida] = useState(false);
  const [contrasenareal, setContrasenareal] = useState(null);
  const [error, setError] = useState(null);
  const [espera, setEspera] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [rol, setRol] = useState(null);

  
  const baseUrl = "https://api.legalallies.es/usuario.php";
//  const baseUrl = "http://localhost/api-legalallies/usuario.php";
  
  const [data, setData] = useState([]);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    usuario: '',
    contrasena: ''
  });
  const handleChange = e => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const cifrar = (texto, clave) => {
    var textoCifrado = CryptoJS.AES.encrypt(texto, clave).toString();
    return textoCifrado;
  }  
  const descifrar = (texto, clave) => {
    var bytes = CryptoJS.AES.decrypt(texto, clave);
    var textoDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    return textoDescifrado;
  }

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    peticionGet();
  }, []);
  
  const usuarioValido = ()=>{
    const dataLogin = {
      "usuario": refUsuario.current.value,
    }
    var existencia = false;
    data.map((x)=>{
      if(x.usuario_usuario == dataLogin.usuario){
        setContrasenareal(descifrar(x.usuario_contrasena,x.usuario_usuario));
        setUsuario(x.usuario_usuario);
        setRol(x.usuario_rol);
        existencia = true;
        if(x.usuario_rol == 3){
        }
      }
    });
    if(existencia){
      setExiste(true);
    }else{
      setExiste(false);
    }
  }
  const contrasenaValida = ()=>{
    const dataLogin = {
      "contrasena": refcontrasena.current.value
    }
    if(contrasenareal == refcontrasena.current.value){
      setCvalida(true);
    }else{
      setCvalida(false);
    }
  }

  const handleLogin = ()=>{
    if(existe && cvalida){
      setError(null);
      setEspera(true);
      auth.setIsAuthenticated(true);
      auth.setUsuario(usuario);
      auth.setRol(rol);
      switch(rol){
        case 1:
          window.localStorage.setItem(
            'rol', JSON.stringify(cifrar('1', usuario))
          );
          break;
        case 2:
          window.localStorage.setItem(
            'rol', JSON.stringify(cifrar('2', usuario))
          );
          break;
        case 3:
          window.localStorage.setItem(
            'rol', JSON.stringify(cifrar('3', usuario))
          );
          break;
        case 4:
          window.localStorage.setItem(
            'rol', JSON.stringify(cifrar('4', usuario))
          );
          break;
      }
      //goto("/");
      window.localStorage.setItem(
        'usuario', JSON.stringify(usuario)
      );
      window.localStorage.setItem(
        'isAuthenticated', JSON.stringify(cifrar('puedes', usuario))
      );
    }else{
      if(!existe){
        setError("El usuario no existe");
      }else{
        if(!cvalida){
          setError("La contraseña es incorrecta")
        }else{
          setError("No tenemos respuesta de lo que pueda estar mal...");
        }
      }
    }
  }

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <body className="hold-transition login-page login">

        <div className="login-box">
          {/* /.login-logo */}
          <div className="card pt-2">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Iniciar sesión</p><br />
                <div className="input-group mb-3">
                  <input type="usuario" className="form-control" placeholder="Correo Electrónico" ref={refUsuario} onChange={usuarioValido} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input ref={refcontrasena} onChange={contrasenaValida} type="password" className="form-control" placeholder="Contraseña" />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                  </div>
                </div>

                { error && 
                <div className="alert alert-danger">
                  {error}
                </div>
                }

                <br />

                <button disabled={espera} onClick={handleLogin} className="btn btn-primary btn-block">Acceder</button>
              {/* /.social-auth-links */}
              <NavLink to="/forgotpassword">
                <p className="mt-3 mb-1">
                  <a>Olvidé mi contraseña</a>
                </p>
              </NavLink>

              <NavLink to="/register">
                <p className="mb-0">
                  <a className="text-center">Registrarme</a>
                </p>
              </NavLink>
              <NavLink to="/" >
                <p className="mb-0">
                  <a className="text-center">Regresar a Inicio</a>
                </p>
              </NavLink>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>


      </body>
    </>
  );
}