import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import '../assets/css/mycss.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { NavLink } from "react-router-dom";
import imageng from '../assets/img/paises/icons8-albania-emoji-48.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFileImage, faFileText, faIdCard, faKey, faLock, faMailForward, faPencil, faPlus, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { exists } from 'i18next';

function Articulogestion({ acceso }) {
  //const baseUrl = "http://localhost/api-legalallies/articulo.php";
  const baseUrl = "https://api.legalallies.es/articulo.php";

  const [permiso, setPermiso] = useState(false);
  var contador = 0;
  const [data, setData] = useState([]);
  const [urlimagenes, setUrlimagenes] = useState([]);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalEliminarMensaje, setModalEliminarMensaje] = useState(false);
  const [mensaje, setMensaje] = useState(false);
  const [articulo_imagen, setArticuloImagen] = useState(null);
  const [articulo_imagen_old, setArticuloImagenOld] = useState(null);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({
    articulo_id: '',
    articulo_nombre: '',
    articulo_fechacreacion: '',
    articulo_url: '',
    articulo_descripcion: '',
    articulo_texto: '',
    articulo_documento: '',
    articulo_imagen: ''
  });
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [espera, setEspera] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setArticuloSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_url && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto && articuloSeleccionado.articulo_documento) {
      setPermiso(true);
      setErrorEnviar(null);
    }    
  };

  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
};

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloImagen(null);
    setFile(null);
  };

  const abrirCerrarModalEliminarMensaje = () => {
    setModalEliminarMensaje(!modalEliminarMensaje);

    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
  };

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  };

  const peticionGet = async () => {
    await axios.get(baseUrl).then(response => {
      setData(response.data["resultado"]);
      setUrlimagenes(response.data["urlimagenes"]);
      console.log(response.data["urlimagenes"]+response.data["resultado"][0]["articulo_imagen"]);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_url && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto && articuloSeleccionado.articulo_documento && articulo_imagen) {
      setPermiso(true);
      setErrorEnviar(null);
    } else {
      setErrorEnviar("Complete el formulario...");
      return;
    }

    if (permiso) {

      var f = new FormData();
      f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
      f.append("articulo_url", articuloSeleccionado.articulo_url);
      f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
      f.append("articulo_texto", articuloSeleccionado.articulo_texto);
      f.append("articulo_documento", articuloSeleccionado.articulo_documento);
      f.append("articulo_imagen", articulo_imagen);
      f.append("METHOD", "POST");

      await axios.post(baseUrl, f , {
        headers:{'Content-Type':"multipart/form-data"},
    }).then(response => {
        peticionGet();
        setEspera(true);
        setErrorEnviar("Se ha creado correctamente el articulo...")
        console.log(response);
      }).catch(error => {
        console.log(error);
        setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
      })

    } else {
      setErrorEnviar("Complete el formulario.")
    }
  };

  const peticionPut = async () => {
    var f = new FormData();
    f.append("articulo_id", articuloSeleccionado.articulo_id);
    f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
    f.append("articulo_url", articuloSeleccionado.articulo_url);
    f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
    f.append("articulo_texto", articuloSeleccionado.articulo_texto);
    f.append("articulo_documento", articuloSeleccionado.articulo_documento);
    f.append("articulo_imagen_old", articulo_imagen_old);
    f.append("articulo_imagen", articulo_imagen);
    f.append("METHOD", "PUT");

    await axios.post(baseUrl, f, { params: { articulo_id: articuloSeleccionado.articulo_id } }).then(response => {
      var dataNueva = data;
      dataNueva.map(articulo => {
        if (articulo.articulo_id === articuloSeleccionado.articulo_id) {
          articulo.articulo_nombre = articuloSeleccionado.articulo_nombre;
          articulo.articulo_url = articuloSeleccionado.articulo_url;
          articulo.articulo_descripcion = articuloSeleccionado.articulo_descripcion;
          articulo.articulo_texto = articuloSeleccionado.articulo_texto;
          articulo.articulo_documento = articuloSeleccionado.articulo_documento;
          articulo.articulo_imagen = articulo_imagen;
        }
      });

      setData(dataNueva);
      abrirCerrarModalEditar();
//      
      console.log(response);
    }).catch(error => {
      console.log(error);
    })
    peticionGet();
  };

  const peticionDelete = async () => {
    var f = new FormData();
    f.append("articulo_id", articuloSeleccionado.articulo_id);
    f.append("articulo_imagen_old", articulo_imagen_old);
    f.append("METHOD", "DELETE");
    const confirmacion = await axios.post(baseUrl, f, { params: { articulo_id: articuloSeleccionado.articulo_id } }).then(response => {
      //      setData(data.filter(articulo => articulo.articulo_articulo !== articuloSeleccionado.articulo_articulo));
      abrirCerrarModalEliminar();
      abrirCerrarModalEliminarMensaje();      
    }).catch(error => {
      console.log(error);
    })
    peticionGet();

  };

  const seleccionararticulo = (articulo, caso) => {
    setArticuloSeleccionado(articulo);
    setArticuloImagenOld(articulo.articulo_imagen);

    (caso === "Editar") ?
      abrirCerrarModalEditar() :
      abrirCerrarModalEliminar()
  };

  const [file, setFile] = useState();

  function convertirImagen(imagen){
    return URL.createObjectURL(imagen);
  }

  const handleChangeImage = (e) => {
    if(e.target.files[0]){
      const selectedFile = e.target.files[0];

      if (selectedFile && selectedFile.type !== 'image/png' && selectedFile.type !== 'image/jpeg') {
        alert('Seleccione una imagen PNG o JPEG.');
        return;
      }
      if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {  // 1 MB
          alert('El tamaño del archivo supera 1 MB');
          return;
      }
  
      setArticuloImagen(e.target.files[0]);
    
        setFile(URL.createObjectURL(e.target.files[0]));
  
    }else {
      return;
    }
  }

  useEffect(() => {
    peticionGet();
  }, [])
  return (
    <div>

      <div class="card  table-hover table-striped table-responsive">
        <div className="card-header">
          <br />

                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/configuracion">Configuración</NavLink>
                        </li>
                        <li className="breadcrumb-item active">
                          Gestionar Artículos
                        </li>
                      </ol>

        </div>
        <div className="card-body">

          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>#</th>
                <th>Imagen</th>
                <th>URL</th>
                <th>Fecha</th>
                <th>Documento</th>
                <th>Título</th>
                <th>Descripción</th>
                <th>Texto</th>
              </tr>
            </thead>
            <tbody>
              {data.map(articulo => (
                <tr key={articulo.articulo_id}>
                  <td>{contador = Number(contador) + 1}</td>
                  <td><img width={"250px"} src={"https://api.legalallies.es/imagenes/"+articulo.articulo_imagen} /></td>
                  <td>{articulo.articulo_url}</td>
                  <td>{articulo.articulo_fechacreacion}</td>
                  <td>{articulo.articulo_documento}</td>
                  <td>{articulo.articulo_nombre}</td>
                  <td>{articulo.articulo_descripcion}</td>
                  <td>{articulo.articulo_texto}</td>
                  <td>
                    <a className="btn btn-primary nav-link" onClick={() => seleccionararticulo(articulo, "Editar")} data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faPencil} /></a>
                    <a className="btn btn-primary nav-link" onClick={() => seleccionararticulo(articulo, "Eliminar")} data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faTrashAlt} /></a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div></div>

      <Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Artículo</ModalHeader>
        <ModalBody>
          <div className="input-group mb-3">
            <input type="file" className="form-control" placeholder="Imagen" name='articulo_imagen' onChange={handleChangeImage} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            </div><br />
          </div>
          <img width={"250px"} src={file} />
          <br/><br/>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Título" name='articulo_nombre' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faIdCard} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="URL" name='articulo_url' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faMailForward} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Descripción" name='articulo_descripcion' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Texto" name='articulo_texto' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Documento" name='articulo_documento' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button /*</ModalFooter>disabled={espera}*/ className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditar}>
        <ModalHeader>Editar articulo</ModalHeader>
        <ModalBody>
          <div className="text-center input-group mb-3">
            <h3>Imagen actual</h3>
          </div>
          <div className="input-group mb-3">
            <img src={"https://api.legalallies.es/imagenes/"+articuloSeleccionado.articulo_imagen} />
          </div>
          <br />
          <div className="input-group mb-3">
            <input type="file" className="form-control" placeholder="Imagen" name='articulo_imagen' onChange={handleChangeImage} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            </div><br />
          </div>
          <div className="text-center input-group mb-3">
            <h3>Nueva imagen</h3>
          </div>
          <img width={"250px"} src={file} />
          <br/><br/>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Título" name='articulo_nombre' onChange={handleChange} value={articuloSeleccionado && articuloSeleccionado.articulo_nombre} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faIdCard} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="URL" name='articulo_url' onChange={handleChange} value={articuloSeleccionado && articuloSeleccionado.articulo_url} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faMailForward} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Descripción" name='articulo_descripcion' onChange={handleChange} value={articuloSeleccionado && articuloSeleccionado.articulo_descripcion} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Texto" name='articulo_texto' onChange={handleChange} value={articuloSeleccionado && articuloSeleccionado.articulo_texto} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Documento" name='articulo_documento' onChange={handleChange} value={articuloSeleccionado && articuloSeleccionado.articulo_documento} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button disabled={espera} className='btn btn-primary' onClick={() => peticionPut()}>Editar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEditar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminar}>
        <ModalHeader>Eliminar articulo</ModalHeader>
        <ModalBody>
          ¿Estás seguro que deseas eliminar el articulo: {articuloSeleccionado.articulo_nombre}?
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => peticionDelete()}>Sí</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalEliminar()}>No</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEliminarMensaje}>
        <ModalHeader>Eliminar articulo</ModalHeader>
        <ModalBody>
          Artículo eliminado correctamente.
        </ModalBody>
        <ModalFooter>
          <button className='btn btn-primary' onClick={() => abrirCerrarModalEliminarMensaje()}>Aceptar</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default Articulogestion