import React, { useState, useEffect } from 'react';
import Usuariogestion from './Usuariogestion';
import { useAuth } from '../auth/AuthProvider';
import Login from './Login';

function Usuario() {
  const auth = useAuth();

  return (
    auth.isAuthenticated && auth.rol == 3 ? <Usuariogestion /> : <Login />
  )
}

export default Usuario