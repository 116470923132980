import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';

function ProtectedRoute() {
//  const [isAuth, setIsAuth] = useState(false);
const auth = useAuth();
  return auth.isAuthenticated ? <Outlet /> : <Navigate to={"/login"} />;
}

export default ProtectedRoute