import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import "../assets/css/icheck-bootstrap/icheck-bootstrap.min.css";
import "../assets/css/login.css";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Forgotpasswordgestion() {
  const [t, i18n] = useTranslation("global");
  const refUsuario = useRef(null);
  const [existe, setExiste] = useState(false);
  const [usuario, setUsuario] = useState(null);
  const [contrasenareal, setContrasenareal] = useState(null);
  const [error, setError] = useState(null);
  const [mensaje, setMensaje] = useState(null);
  const [espera, setEspera] = useState(false);

//  const baseUrl = "http://localhost/api-legalallies/email.php";
  const baseUrl = "https://api.legalallies.es/email.php";
  const baseUrlU = "https://api.legalallies.es/usuario.php";

  const [data, setData] = useState([]);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({
    usuario: ''
  });
  const handleChange = e => {
    const { name, value } = e.target;
    setUsuarioSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  
  const descifrar = (texto, clave) => {
    var bytes = CryptoJS.AES.decrypt(texto, clave);
    var textoDescifrado = bytes.toString(CryptoJS.enc.Utf8);
    return textoDescifrado;
  }

  const peticionGet = async () => {
    await axios.get(baseUrlU).then(response => {
      setData(response.data);
    }).catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    peticionGet();
  }, [])

  const usuarioValido = ()=>{
    var existencia = false;
    data.map((x)=>{
      if(x.usuario_usuario == refUsuario.current.value){
        existencia = true;
        setUsuario(x.usuario_usuario);
        setContrasenareal(descifrar(x.usuario_contrasena,x.usuario_usuario));
        setExiste(true);
      }
    });
  }

  const peticionPost = async () => {
    var f = new FormData();
    f.append("usuario", usuario);
    f.append("contrasena", contrasenareal);
    f.append("METHOD", "POST");
    await axios.post(baseUrl, f).then(response => {
      setMensaje("Se le ha enviado un correo a "+usuario+" con su contraseña");
    }).catch(error => {
      setError(error);
    })
  };

  const handleLogin = ()=>{
    if(refUsuario.current.value==usuario){
      peticionPost();
      setError(null);
      setEspera(true);
    }else{
      setError("El usuario no existe");
    }
  }

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <body className="hold-transition login-page login">
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">¿Olvidaste tu contraseña? Aquí puedes recuperarla fácilmente.</p>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">@</span>
                  </div>
                  <input type="usuario" className="form-control" placeholder="Correo Electrónico" ref={refUsuario} onChange={usuarioValido} />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                </div>

                { error && 
                <div className="alert alert-danger">
                  {error}
                </div>
                }
                { espera && 
                <div className="alert alert-success">
                  {mensaje}
                </div>
                }

                <br />

                <div className="row">
                  <div className="col-12">
                    <button disabled={espera} onClick={handleLogin} className="btn btn-primary btn-block">Recuperar contraseña</button>
                  </div>
                  {/* /.col */}
                </div>

              <NavLink to="/login">
                <p className="mt-3 mb-1">
                  <a href="login.html">Iniciar Sesión</a>
                </p>
              </NavLink>

              <NavLink to="/register">
                <p className="mb-0">
                  <a href="register.html" className="text-center">Registrarme</a>
                </p>
              </NavLink>
              <NavLink to="/">
                <p className="mb-0">
                  <a className="text-center">Regresar a Inicio</a>
                </p>
              </NavLink>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>


      </body>
    </>
  );
}