import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
//import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { NavLink } from "react-router-dom";

import "../assets/css/datatables-bs4/css/dataTables.bootstrap4.min.css";
import "../assets/css/datatables-responsive/css/responsive.bootstrap4.min.css";
import Configuraciongestion from './Configuraciongestion';
import { useAuth } from '../auth/AuthProvider';
import Login from './Login';

function Configuracion() {
  const auth = useAuth();

  return (
    auth.isAuthenticated && auth.rol == 3 ? <Configuraciongestion  /> : <Login  />
  )
}

export default Configuracion