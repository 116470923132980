import React from "react";
import wp from "../assets/img/proyecto/wp.png";
import telegram from "../assets/img/proyecto/telegram.png";
import og_yaranda from "../assets/img/proyecto/og_yaranda.png";
import inst from "../assets/img/proyecto/in.png";
import facebook from "../assets/img/proyecto/facebook.png";
import X from "../assets/img/proyecto/X.png";
import youtube from "../assets/img/proyecto/youtube.png";
import { useTranslation } from "react-i18next";
import Mailer from "./Mailer";
import { NavLink } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGear, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  const [t, i18n] = useTranslation("global");
  const auth = useAuth();
  return (
    <>
      <footer
        className="fl-page-footer-wrap"
        itemScope="itemscope"
        itemType="https://schema.org/WPFooter"
        role="contentinfo"
      >
        <div
          className="fl-row fl-row-full-width fl-row-bg-color fl-node-60b0045d51dfd fl-row-default-height fl-row-align-center"
          data-node="60b0045d51dfd"
        >
          <div className="fl-row-content-wrap">
            <div className="fl-row-content fl-row-fixed-width fl-node-content">

              {/* Main content */}
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* left column */}
                    <div className="col-md-6">
                      <br></br>

                      {/* general form elements */}
                      <div className="">
                        <div className="card-header">
                          <h4>{t("body.texto17")}</h4>
                        </div>
                        {/* /.card-header */}
                        {/* form start */}

                        <div
                          className="fl-col-group fl-node-60b0033d27911 fl-col-group-custom-width"
                          data-node="60b0033d27911"
                        >
                          <div
                            className="fl-col fl-node-60b0033d27919 fl-col-has-cols"
                            data-node="60b0033d27919"
                          >
                            <div className="fl-col-content fl-node-content">
                              <br></br><br></br>
                              <div
                                className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                                data-node="60b0033d2792d"
                              >
                                <div
                                  className="fl-col fl-node-60b0033d2792e"
                                  data-node="60b0033d2792e"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                                      data-node="60b0033d27930"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                          <h6>
                                          <FontAwesomeIcon icon={faPhone} />
                                            <strong> {t("body.texto18")}</strong>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="fl-col-group fl-node-60b0033d2792d fl-col-group-nested"
                                data-node="60b0033d2792d"
                              >
                                <div
                                  className="fl-col fl-node-60b0033d2792e"
                                  data-node="60b0033d2792e"
                                >
                                  <div className="fl-col-content fl-node-content">
                                    <div
                                      className="fl-module fl-module-rich-text fl-node-60b0033d27930"
                                      data-node="60b0033d27930"
                                    >
                                      <div className="fl-module-content fl-node-content">
                                        <div className="fl-rich-text">
                                          <h6>
                                          <FontAwesomeIcon icon={faEnvelope} />
                                            <strong> {t("body.texto20")}</strong>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.card */}
                    </div>
                    {/*/.col (left) */}
                    {/* right column */}
                    <div className="col-md-6">
                      <br></br>
                      {/* general form elements disabled */}
                      <div className="">
                        <div className="card-header">
                          <h5>{t("body.texto22")}</h5>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <Mailer />
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.card */}
                    </div>
                    {/*/.col (right) */}
                  </div>
                  {/* /.row */}
                </div>{/* /.container-fluid */}
              </section>

              <div
                className="fl-col-group fl-node-60b0033d27915"
                data-node="60b0033d27915"
              >
                <div
                  className="fl-col fl-node-60b0033d27932"
                  data-node="60b0033d27932"
                >
                  <div className="fl-col-content fl-node-content">
                    <div
                      className="fl-module fl-module-html fl-node-60b0033d27933"
                      data-node="60b0033d27933"
                    >
                      <div className="fl-module-content fl-node-content">
                        <div className="fl-html">
                          <div className="redes-sociales">
                            <ul>
                              <li className="facebook">
                                <a target="_blank" href="https://www.facebook.com/LegalAllies7">
                                  <img src={facebook} />
                                </a>
                              </li>
                              <li className="youtube">
                                <a target="_blank" href="https://www.youtube.com/@legalallies7">
                                  <img src={youtube} />
                                </a>
                              </li>
                              <li className="instagram">
                                <a target="_blank" href="https://www.instagram.com/legal.allies">
                                  <img src={og_yaranda} />
                                </a>
                              </li>
                              <li className="wapp">
                                <a href="https://wa.me/34605893451" target="_blank">
                                  <img src={wp} />
                                </a>
                              </li>
                              <li className="wapp">
                                <a href="https://t.me/legalallies" target="_blank">
                                  <img src={telegram} />
                                </a>
                              </li>
                              <li className="wapp">
                                <a href="https://x.com/legalallies7" target="_blank">
                                  <img src={X} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fl-page-footer">
          <div className="fl-page-footer-container container">
            <div className="fl-page-footer-row row">
              <div className="col-md-12 text-center clearfix">
                <div className="fl-page-footer-text fl-page-footer-text-1">
                  Copyright 2024 © Legal Allies | {t("footer.texto2")}
                </div>

                {auth.isAuthenticated && auth.rol == 3 ?
                  <NavLink to="/configuracion">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button"><FontAwesomeIcon icon={faGear} /></a>
                  </NavLink>
                  : null}
              </div>
            </div>
          </div>
        </div>
        <br />
      </footer>
    </>
  );
}
