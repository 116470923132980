import React, { useContext, createContext, useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';

const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: false,
    setUsuario: "",
    setRol: "",
    usuario: "",
    rol: ""
});
function AuthProvider({children}) {
    const [ isAuthenticated, setIsAuthenticated ] = useState(false);
    const [ usuario, setUsuario ] = useState("");
    const [ rol, setRol ] = useState("");

    const descifrar = (texto, clave) => {
      var bytes = CryptoJS.AES.decrypt(texto, clave);
      var textoDescifrado = bytes.toString(CryptoJS.enc.Utf8);
      return textoDescifrado;
    }
  
    useEffect(() => {
      if(window.localStorage.getItem('usuario')){
        const usuarioL = window.localStorage.getItem('usuario');
        var usuarioOK = null;
        if(usuarioL){
          usuarioOK = JSON.parse(usuarioL);
          setUsuario(usuarioOK);
        }
        if(usuarioOK){
          const rolL = window.localStorage.getItem('rol');
          if(rolL){
            const rolOK =  JSON.parse(rolL);
            const rolD = descifrar(rolOK, usuarioOK);
            setRol(rolD);
          }
          const isAuthenticatedL = window.localStorage.getItem('isAuthenticated');
          if(isAuthenticatedL){
            const isAuthenticatedOK =  JSON.parse(isAuthenticatedL);
            const isAuthenticatedD = descifrar(isAuthenticatedOK, usuarioOK);
            setIsAuthenticated(isAuthenticatedD);
          }
        }
    
      }
    }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, usuario, setUsuario, rol, setRol }}>
        { children }
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => useContext(AuthContext);