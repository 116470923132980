import React, { useState, useEffect } from 'react';
import 'bootstrap';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import background from "../assets/img/proyecto/derecho a la familia/custodia/1800x800/2.jpg";
import lateral from "../assets/img/proyecto/derecho a la familia/custodia/1800x800/3.jpg";
import { NavLink } from "react-router-dom";
import { useAuth } from '../auth/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faEnvelope, faFileImage, faFileText, faIdCard, faKey, faLock, faMailForward, faPencil, faPlus, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';

export default function ArticulogestionBoton({ documento, urlweb }) {
  const auth = useAuth();
  const [t, i18n] = useTranslation("global");
  //const baseUrl = "http://localhost/api-legalallies/articulo.php";
  const baseUrl = "https://api.legalallies.es/articulo.php";

  const [idioma, setIdioma] = i18n.language;
  const [data, setData] = useState([]);
  const [urlimagenes, setUrlimagenes] = useState([]);
  const [permiso, setPermiso] = useState(false);
  const [articulo_imagen, setArticuloImagen] = useState(null);
  const [articulo_imagen_old, setArticuloImagenOld] = useState(null);
  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalArticulo, setModalArticulo] = useState(false);
  const [espera, setEspera] = useState(false);
  const [file, setFile] = useState();
  
  const [errorEnviar, setErrorEnviar] = useState(null);
  const [articuloSeleccionado, setArticuloSeleccionado] = useState({
    articulo_id: '',
    articulo_nombre: '',
    articulo_fechacreacion: '',
    articulo_url: '',
    articulo_descripcion: '',
    articulo_texto: '',
    articulo_documento: '',
    articulo_imagen: ''
  });
  const handleChange = e => {
    const { name, value } = e.target;
    setArticuloSeleccionado((prevState) => ({
      ...prevState,
      [name]: value
    }))

    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto ) {
      setPermiso(true);
      setErrorEnviar(null);
    }
  };
  const abrirCerrarModalInsertar = () => {
    setModalInsertar(!modalInsertar);
    setEspera(false);
    setErrorEnviar(false);

    setArticuloSeleccionado("articulo_id", '');
    setArticuloSeleccionado("articulo_nombre", '');
    setArticuloSeleccionado("articulo_fechacreacion", '');
    setArticuloSeleccionado("articulo_url", '');
    setArticuloSeleccionado("articulo_descripcion", '');
    setArticuloSeleccionado("articulo_texto", '');
    setArticuloSeleccionado("articulo_documento", '');
    setArticuloImagen(null);
    setArticuloImagenOld(null);
    setFile(null);
  };

  const abrirCerrarModalArticulo = () => {
    setModalArticulo(!modalArticulo);
  };

  const peticionGet = async () => {
    console.log(t("idioma.texto1"));
    await axios.get(baseUrl, {"articulo_documento": documento}).then(response => {
      setData(response.data["resultado"]);
      setUrlimagenes(response.data["urlimagenes"]);
//      console.log(response.data['resultado'].length)
//      console.log(response.data["urlimagenes"] + response.data["resultado"][0]["articulo_imagen"]);
    }).catch(error => {
      console.log(error);
    })
  };

  const peticionPost = async () => {
    if (articuloSeleccionado.articulo_nombre && articuloSeleccionado.articulo_descripcion && articuloSeleccionado.articulo_texto && articulo_imagen) {
      setPermiso(true);
      setErrorEnviar(null);
    } else {
      setErrorEnviar("Complete el formulario...");
      return;
    }

    if (permiso) {

      var f = new FormData();
      f.append("articulo_nombre", articuloSeleccionado.articulo_nombre);
      f.append("articulo_url", urlweb);
      f.append("articulo_descripcion", articuloSeleccionado.articulo_descripcion);
      f.append("articulo_texto", articuloSeleccionado.articulo_texto);
      f.append("articulo_documento", documento);
      f.append("articulo_documento_id", 1);
      f.append("articulo_imagen", articulo_imagen);
      f.append("METHOD", "POST");

      await axios.post(baseUrl, f, {
        headers: { 'Content-Type': "multipart/form-data" },
      }).then(response => {
        peticionGet();
        setEspera(true);
        setErrorEnviar("Se ha creado correctamente el articulo...")
        console.log(response);
      }).catch(error => {
        console.log(error);
        setErrorEnviar("Ha ocurrido un error fuera de nuestro alcance, puede que tengas problemas con la conexión...")
      })

    } else {
      setErrorEnviar("Complete el formulario.")
    }
  };

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (selectedFile && selectedFile.type !== 'image/png' && selectedFile.type !== 'image/jpeg') {
        alert('Seleccione una imagen PNG o JPEG.');
        return;
      }
      if (selectedFile && selectedFile.size > 1 * 1024 * 1024) {  // 1 MB
        alert('El tamaño del archivo supera 1 MB');
        return;
      }

      setArticuloImagen(e.target.files[0]);

      setFile(URL.createObjectURL(e.target.files[0]));

    } else {
      return;
    }
  }


  const seleccionararticulo = (articulo) => {
    setArticuloSeleccionado(articulo);
    abrirCerrarModalArticulo();
  };


    useEffect(() => {
      peticionGet();
    }, [])
  

  return (
    <div>
        

  <div>


  <main className="container">

    <div className="row g-5">
      <div className="col-md-8">

      <div className="p-4 mb-3 bg-body-tertiary rounded">
              <img className="d-block w-100" src={background} alt="First slide"/>
          </div>
        <article className="blog-post">
          <h3 className="display-5 link-body-emphasis mb-1">{t("familia.texto1")}</h3>
          <p className="blog-post-meta">January 1, 2024</p>
          <p>{t("custodia.texto1")}</p>
          <hr />
          <h3>{t("custodia.texto2")}</h3>
          <p>{t("custodia.texto3")}</p>
          <ul>
            <li>{t("custodia.texto4")}</li>
            <li>{t("custodia.texto5")}</li>
          </ul>
          <p>{t("custodia.texto6")}</p>
          <ol>
            <li>{t("custodia.texto7")}</li>
              <ol>
                <li>{t("custodia.texto8")}</li>
                <li>{t("custodia.texto9")}</li>
                <li>{t("custodia.texto10")}</li>
              </ol>
            <li>{t("custodia.texto11")}</li>
              <ol>
                <li>{t("custodia.texto12")}</li>
                <li>{t("custodia.texto13")}</li>
                <li>{t("custodia.texto14")}</li>
                <li>{t("custodia.texto15")}</li>
                <li>{t("custodia.texto16")}</li>
              </ol>
          </ol>
          <dl>
            <dt>{t("custodia.texto17")}</dt>
          </dl>
          <p>{t("custodia.texto18")}</p>
          <ol>
            <li>{t("custodia.texto19")}</li>
            <li>{t("custodia.texto20")}</li>
            <li>{t("custodia.texto21")}</li>
            <li>{t("custodia.texto22")}</li>
            <li>{t("custodia.texto23")}</li>
          </ol>
          <dl>
            <dt>{t("custodia.texto24")}</dt>
          </dl>
        </article>
        <nav className="blog-pagination" aria-label="Pagination">
          <a className="btn btn-outline-primary rounded-pill" href="#">Older</a>
        </nav>
      </div>
      <div className="col-md-4">
        <div className="position-sticky" style={{top: '2rem'}}>
          <div className="p-4 mb-3 bg-body-tertiary rounded">
              <img className="d-block w-100" src={lateral} alt="First slide"/>
          </div>
          <div>
            <h4 className="fst-italic">Artículos Relacionados</h4>
            {
                                  auth.isAuthenticated && auth.rol == 3
                                  ? 
                                  <div style={{ textAlign: 'right' }}>
                                    <button className='btn btn-primary' onClick={() => abrirCerrarModalInsertar()}><FontAwesomeIcon icon={faPlus} /></button>
                                  </div>
                                  : 
                                  true
                          }
            <ul className="list-unstyled">
              {data.map(articulo => (
                articulo.articulo_documento == documento
                ?
              <li>
                <a className="d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center py-3 link-body-emphasis text-decoration-none border-top" onClick={() => seleccionararticulo(articulo)}>
                  <img className="d-block w-50 h-50" src={"https://api.legalallies.es/imagenes/" + articulo.articulo_imagen} alt="First slide"/>
                  <div className="col-lg-8">
                    <h6 className="mb-0">{articulo.articulo_nombre}</h6>
                    <small className="text-body-secondary">{articulo.articulo_fechacreacion}</small>
                  </div>
                </a>
              </li>
              :
              true
            ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div className="py-5 text-center text-body-secondary bg-body-tertiary">
  </div>
</div>

<Modal isOpen={modalInsertar}>
        <ModalHeader>Insertar Artículo</ModalHeader>
        <ModalBody>
          <div className="input-group mb-3">
            <input type="file" className="form-control" placeholder="Imagen" name='articulo_imagen' onChange={handleChangeImage} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            </div><br />
          </div>
          <img width={"250px"} src={file} />
          <br /><br />
          <div className="input-group mb-3">
            <input disabled={true} type="text" className="form-control" placeholder="URL" name='articulo_url' onChange={handleChange} value={urlweb} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faMailForward} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input disabled={true} type="text" className="form-control" placeholder="Documento" name='articulo_documento' value={documento} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Título" name='articulo_nombre' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faIdCard} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Descripción" name='articulo_descripcion' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Texto" name='articulo_texto' onChange={handleChange} required />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faFileText} />
              </div>
            </div>
          </div>

          {
            errorEnviar && !espera &&
            <div className="alert alert-danger">
              {errorEnviar}
            </div>
          }
          {espera &&
            <div className="alert alert-success">
              {errorEnviar}
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <button disabled={espera} className='btn btn-primary' onClick={() => peticionPost()}>Insertar</button>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalInsertar()}>Cancelar</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalArticulo}>
        <ModalHeader><h3 className="text-center">{t("familia.texto1")}</h3></ModalHeader>
        <ModalBody>


        <main className="container">

<div className="row g-5">
  <div className="col-md-12">
  <div className="p-4 mb-3 bg-body-tertiary rounded">
          <img className="d-block w-100" src={"https://api.legalallies.es/imagenes/"+articuloSeleccionado.articulo_imagen} alt="First slide"/>
      </div>
    <article className="blog-post">
      <p className="blog-post-meta">{articuloSeleccionado.articulo_fechacreacion}</p>
      <p>{articuloSeleccionado.articulo_descripcion}</p>
      <hr />
      <h3>{articuloSeleccionado.articulo_nombre}</h3>
      <p>{articuloSeleccionado.articulo_texto}</p>
      <dl>
        <dt>{articuloSeleccionado.articulo_url}</dt>
      </dl>
    </article>
  </div>
</div>
</main>

        </ModalBody>
        <ModalFooter>
          <button className='btn btn-danger' onClick={() => abrirCerrarModalArticulo()}>Cerrar</button>
        </ModalFooter>
      </Modal>


      <nav className="text-center blog-pagination" aria-label="Pagination">
          <a className="btn btn-outline-primary rounded-pill" href="#"><FontAwesomeIcon icon={faChevronUp} /></a>
        </nav><br />


    </div>
  )
}

const BodyContainer = styled.body`

      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }

      .b-example-divider {
        width: 100%;
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }

      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }

      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }

      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }

      .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }

      .btn-bd-primary {
        --bd-violet-bg: #712cf9;
        --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

        --bs-btn-font-weight: 600;
        --bs-btn-color: var(--bs-white);
        --bs-btn-bg: var(--bd-violet-bg);
        --bs-btn-border-color: var(--bd-violet-bg);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-hover-bg: #6528e0;
        --bs-btn-hover-border-color: #6528e0;
        --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
        --bs-btn-active-color: var(--bs-btn-hover-color);
        --bs-btn-active-bg: #5a23c8;
        --bs-btn-active-border-color: #5a23c8;
      }

      .bd-mode-toggle {
        z-index: 1500;
      }

      .bd-mode-toggle .dropdown-menu .active .bi {
        display: block !important;
      }


background-color: #343a40;
  h1 {
    color: #f3eded;
  }
  h3 {
    color: #d4ac0d;
    font-weight: 400;
    span {
      font-weight: bold;
      color: white;
    }
    padding: 0.6rem;
  }
  .azulTonalidad {
      background-color: #001952;
  }
  .BlancoTonalidad {
      background-color: #ffffff;
  }

`;